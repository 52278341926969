/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// next
import dynamic from 'next/dynamic';

// prop-types is library for typechecking of props
import PropTypes from 'prop-types';

// MUI Icons
const Schedule = dynamic(() => import('@mui/icons-material/Schedule'), { loading: () => null });
const Star = dynamic(() => import('@mui/icons-material/Star'), { loading: () => null });
const StarOutline = dynamic(() => import('@mui/icons-material/StarOutline'), { loading: () => null });
const StarHalf = dynamic(() => import('@mui/icons-material/StarHalf'), { loading: () => null });

// PRO
const MDAvatar = dynamic(() => import('components/MDAvatar'), { loading: () => null });
const MDBox = dynamic(() => import('components/MDBox'), { loading: () => null });
const MDTypography = dynamic(() => import('components/MDTypography'), { loading: () => null });

function DefaultReviewCard({
  color, image, name, date, review, rating,
}) {
  const ratings = {
    0.5: [
      <StarOutline key={11} />,
      <StarOutline key={12} />,
      <StarOutline key={13} />,
      <StarOutline key={14} />,
      <StarOutline key={15} />,
    ],
    1: [
      <Star key={21} />,
      <StarOutline key={22} />,
      <StarOutline key={23} />,
      <StarOutline key={24} />,
      <StarOutline key={25} />,
    ],
    1.5: [
      <Star key={31} />,
      <StarHalf key={32} />,
      <StarOutline key={33} />,
      <StarOutline key={34} />,
      <StarOutline key={35} />,
    ],
    2: [
      <Star key={41} />,
      <Star key={42} />,
      <StarOutline key={43} />,
      <StarOutline key={44} />,
      <StarOutline key={45} />,
    ],
    2.5: [
      <Star key={51} />,
      <Star key={52} />,
      <StarHalf key={53} />,
      <StarOutline key={54} />,
      <StarOutline key={55} />,
    ],
    3: [
      <Star key={61} />,
      <Star key={62} />,
      <Star key={63} />,
      <StarOutline key={64} />,
      <StarOutline key={65} />,
    ],
    3.5: [
      <Star key={71} />,
      <Star key={72} />,
      <Star key={73} />,
      <StarHalf key={74} />,
      <StarOutline key={75} />,
    ],
    4: [
      <Star key={81} />,
      <Star key={82} />,
      <Star key={83} />,
      <Star key={84} />,
      <StarOutline key={85} />,
    ],
    4.5: [
      <Star key={91} />,
      <Star key={92} />,
      <Star key={93} />,
      <Star key={94} />,
      <StarHalf key={95} />,
    ],
    5: [
      <Star key={101} sx={{ color: 'gold' }} />,
      <Star key={102} sx={{ color: 'gold' }} />,
      <Star key={103} sx={{ color: 'gold' }} />,
      <Star key={410} sx={{ color: 'gold' }} />,
      <Star key={105} sx={{ color: 'gold' }} />,
    ],
  };

  return (
    <MDBox
      variant={color === 'transparent' ? 'contained' : 'gradient'}
      bgColor={color}
      borderRadius="xl"
      shadow={color === 'transparent' ? 'none' : 'md'}
      p={3}
    >
      {image && (
        <MDAvatar
          src={image}
          alt={name}
          variant="rounded"
          size="lg"
          shadow="md"
          sx={{ mt: -5, mb: 1 }}
        />
      )}
      <MDBox lineHeight={1}>
        <MDTypography
          display="block"
          variant={image ? 'button' : 'h6'}
          component="h2"
          fontWeight="bold"
          color={color === 'transparent' || color === 'light' ? 'dark' : 'white'}
          mb={0.5}
        >
          {name}
        </MDTypography>
        <MDTypography
          variant={image ? 'caption' : 'button'}
          fontWeight="regular"
          lineHeight={1}
          color={color === 'transparent' || color === 'light' ? 'text' : 'white'}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Schedule />
          &nbsp;
          {date}
        </MDTypography>
      </MDBox>
      <MDTypography
        variant="body2"
        color={color === 'transparent' || color === 'light' ? 'text' : 'white'}
        my={4}
        fontWeight="regular"
      >
        &quot;
        {review}
        &quot;
      </MDTypography>
      <MDTypography
        variant="h4"
        component="h3"
        color={color === 'transparent' || color === 'light' ? 'text' : 'white'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          ml: 0.375,

          '& .material-icons-round': {
            ml: -0.375,
          },
        }}
      >
        {ratings[rating]}
      </MDTypography>
    </MDBox>
  );
}

// Setting default values for the props of DefaultReviewCard
DefaultReviewCard.defaultProps = {
  color: 'transparent',
  image: '',
};

// Typechecking props for the DefaultReviewCard
DefaultReviewCard.propTypes = {
  color: PropTypes.oneOf([
    'transparent',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light',
  ]),
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
  rating: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired,
};

export default DefaultReviewCard;
